import React from "react"
import { StaticQuery, graphql } from "gatsby"
import AboutStaffMembers from "../about/aboutStaffMembers"

const query = graphql`
    query {
        sanityStaffWidgets(title: { eq: "who_we_are" }) {
            id
            title
            widget_staff {
                id
                first_name
                last_name
                job_title
                staff_image {
                    asset {
                        fluid(maxWidth: 1000) {
                            ...GatsbySanityImageFluid_noBase64
                        }
                    }
                }
            }
        }
    }
`

export default () => (
    <StaticQuery
        query={query}
        render={({ sanityStaffWidgets: { widget_staff } }) => {
            return (
                <>
                    <AboutStaffMembers staff={widget_staff}/>
                </>
            )
        }}
    />
)