import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
/* eslint-disable jsx-a11y/accessible-emoji */

const AboutStaffMembersDiv = styled.div`
    position: relative;
    margin-bottom: 18px;

    @media only screen and (min-width: 750px) {
        margin-bottom: 108px;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 30px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const DesktopCircleDiv = styled.div`
    position: absolute;
    right: 0;
    top: -100px;
    display: none;

    @media only screen and (min-width: 750px) {
        display: block;
    }

    @media only screen and (min-width: 1024px) {
        top: -193px;
    }
`;

const Headline = styled.h3`
    font-size: 24px;
    letter-spacing: -1px;
    color: #000000;
    margin-bottom: 38px;
`;

const FireEmoji = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

const GridDiv = styled.div`
    @media only screen and (min-width: 750px) {
        margin-left: -16px;
    }
`;

const StaffMemberDiv = styled.div`
    width: 100%;
    margin-bottom: 54px;

    @media only screen and (min-width: 750px) {
        width: 33.33%;
        padding-left: 16px;
        margin-bottom: 42px;
        float: left;
    }

    @media only screen and (min-width: 1024px) {
        width: 25%;
    }
`;

const StaffMemberName = styled.h4`
    font-size: 20px;
    color: #282828;
    margin: 22px 0 4px 0;

    @media only screen and (min-width: 750px) {
        font-size: 16px;
        margin: 21px 0 1px 0;
    }
`;

const StaffMemberJobTitle = styled.p`
    font-family: 'Graphik Regular', Helvetica, Arial, sans-serif;
    font-size: 20px;
    color: #282828;
    margin: 0;

    @media only screen and (min-width: 750px) {
        font-size: 16px;
    }
`;

const ClearFixDiv = styled.div`
    display: block;
    clear: both;
`;

function AboutStaffMembers({ staff }) {
    return (
        <AboutStaffMembersDiv>
            <DesktopCircleDiv>
                <svg width="332" height="700" viewBox="0 0 332 700" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M370.878 1C575.989 1 740.797 149.149 740.797 349.98C740.797 550.851 575.989 699 370.878 699C169.123 699 1.0005 550.851 1.0005 349.98C1.0005 149.149 169.123 1 370.878 1" stroke="#4157FE"/>
                </svg>
            </DesktopCircleDiv>
            <WrapperDiv>
                <Headline>Meet the team <FireEmoji role="img" aria-label="Fire Emoji">🔥</FireEmoji></Headline>
                <GridDiv>
                    {staff.map(({ first_name, last_name, job_title, staff_image }, i) => (
                        <StaffMemberDiv key={`staff-memeber-${i+1}`}>
                            <Img fluid={staff_image.asset.fluid} />
                            <StaffMemberName>{first_name} {last_name}</StaffMemberName>
                            <StaffMemberJobTitle>{job_title}</StaffMemberJobTitle>
                        </StaffMemberDiv>
                    ))}
                    <ClearFixDiv></ClearFixDiv>
                </GridDiv>
            </WrapperDiv>
        </AboutStaffMembersDiv>
    )
}

export default AboutStaffMembers