import React, { useEffect } from "react"
import styled from "styled-components"

const AboutWorkWithUsDiv = styled.div`
    margin-bottom: 73px;
    text-align: center;

    @media only screen and (min-width: 750px) {
        margin-bottom: 180px;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 30px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const IntercomLink = styled.span`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    font-family: 'Graphik Bold', Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: #4157FE;
    font-size: 79px;
    line-height: 80px;
    letter-spacing: -4px;
    cursor: pointer;

    @media only screen and (min-width: 750px) {
        font-size: 90px;
        line-height: 74px;
        border-bottom: 6px solid #4157FE;
    }

    @media only screen and (min-width: 1024px) {
        font-size: 118px;
        line-height: 92px;
        letter-spacing: -6px;
        border-bottom: 8px solid #4157FE;
    }

    &:after {
        content: '';
        display: block;
        width: 245px;
        height: 4px;
        background-color: #4157FE;
        margin: 4px auto 0;

        @media only screen and (min-width: 750px) {
            display: none;
        }
    }

    &:hover {
        opacity: 0.8;
    }

    br {
        @media only screen and (min-width: 750px) {
            display: none;
        }
    }
`;

const AboutWorkWithUs = () => {
    const openIntercom = () => {
        const intercomToggle = document.querySelector('.intercom-toggle');

        if (intercomToggle) {
            intercomToggle.click();
        }
    }

    return (
        <AboutWorkWithUsDiv>
            <WrapperDiv>
                <IntercomLink onClick={openIntercom}>
                    Work <br /> with us
                </IntercomLink>
            </WrapperDiv>
        </AboutWorkWithUsDiv>
    );
}

export default AboutWorkWithUs