import React from "react"
import SEO from "../components/seo"
import metaImage from "../images/meta-image.png"
import AboutHero from "../components/about/aboutHero"
import AboutDetails from "../components/about/aboutDetails"
import AboutQuote from "../components/about/aboutQuote"
import AboutStaffWidget from "../components/about/aboutStaffWidget"
import AboutWorkWithUs from "../components/about/aboutWorkWithUs"
import AboutApps from "../components/about/aboutApps"

const aboutTemplate = ({ data, ...props }) => {
    return (
        <>
            <SEO
                title="About"
                url="https://pointercreative.com/about"
                image={`https://pointercreative.com${metaImage}`}
            />
            <AboutHero/>
            <AboutDetails/>
            <AboutQuote/>
            <AboutStaffWidget/>
            <AboutWorkWithUs/>
            <AboutApps/>
        </>
    )
}

export default aboutTemplate