import React, { useState } from "react"
import styled from "styled-components"
import beverageImage from "../../images/about-beverage.jpg"
import supplementsImage from "../../images/about-supplements.jpg"
import fashionImage from "../../images/about-fashion.jpg"
import kidsImage from "../../images/about-kids.jpg"
import electronicsImage from "../../images/about-electronics.jpg"
import lifestyleImage from "../../images/about-lifestyle.jpg"
import wellnessImage from "../../images/about-wellness.jpg"
import cbdImage from "../../images/about-cbd.jpg"
import musicImage from "../../images/about-music.jpg"
import artImage from "../../images/about-art.jpg"

const AboutQuoteDiv = styled.div`
    overflow: hidden;
    margin-bottom: 100px;

    @media only screen and (min-width: 1024px) {
        overflow: visible;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 30px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const Quote = styled.h2`
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
    color: #DADADA;
    margin-bottom: 40px;
    cursor: default;

    @media only screen and (min-width: 750px) {
        font-size: 55px;
        line-height: 65px;
    }

    @media only screen and (min-width: 1024px) {
        max-width: 85%;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 70px;
        line-height: 86px;
        letter-spacing: -4px;
    }

    @media only screen and (min-width: 1400px) {
        font-size: 80px;
        line-height: 96px;
        max-width: none;
    }
`;

const QuoteLink = styled.span`
    position: relative;
    transition: color 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    display: inline-block;
    text-decoration: none;
    color: #DADADA;

    &.active {
        @media only screen and (max-width: 1023px) {
            color: #4157FE;
        }
    }

    &:hover {
        @media only screen and (min-width: 1024px) {
            color: #4157FE;

            img {
                visibility: visible;
                height: auto;
                opacity: 1;
            }
        }
    }
`;

const QuoteImage = styled.img`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    z-index: 100;
    position: absolute;
    top: -93px;
    right: -250px;
    width: 216px;
    max-width: none;
    height: 0;
    opacity: 0;
    visibility: hidden;
`;

const ImageCarouselWrapper = styled.div`
    width: calc(100% + 60px);
    height: 294px;
    overflow: hidden;
    margin-left: -30px;

    @media only screen and (min-width: 1024px) {
        display: none;
    }
`;

const ImageCarouselScrollingDiv = styled.div`
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 30px;

    @media only screen and (min-width: 1024px) {
        display: none;
    }
`;

const ImageCarousel = styled.div`
    width: calc(2400px + 100% - 234px);

    @media only screen and (min-width: 750px) {
        width: calc(2400px + 100% - 244px);
    }
`;

const Image = styled.img`
    display: inline-block;
    width: 216px;
    margin: 0 24px 0 0;
`;

const AboutQuote = () => {
    const [activeLink, setActiveLink] = useState('beverage');

    const updateActiveLink = (event) => {
        const scrollPosition = event.target.scrollLeft;

        if (!scrollPosition) { return; }

        const carouselImages = event.target.querySelectorAll('img');

        carouselImages.forEach((carouselImage) => {
            const imagePosition = carouselImage.getBoundingClientRect().right;

            if (imagePosition < 0 || imagePosition > 244) { return; }

            const imageHandle = carouselImage.getAttribute('data-handle');

            setActiveLink(imageHandle);
        });
    }

    return (
        <AboutQuoteDiv>
            <WrapperDiv>
                <Quote>
                    We have worked with an array of industries from&nbsp;
                    <QuoteLink className={activeLink === 'beverage' ? 'active' : null}>
                        beverage
                        <QuoteImage src={beverageImage} alt="Beverage Image"/>
                    </QuoteLink>
                    &nbsp;to&nbsp;
                    <QuoteLink className={activeLink === 'supplements' ? 'active' : null}>
                        supplements
                        <QuoteImage src={supplementsImage} alt="Supplements Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'fashion' ? 'active' : null}>
                        fashion
                        <QuoteImage src={fashionImage} alt="Fashion Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'kids' ? 'active' : null}>
                        kids
                        <QuoteImage src={kidsImage} alt="Kids Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'electronics' ? 'active' : null}>
                        electronics
                        <QuoteImage src={electronicsImage} alt="Electronics Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'lifestyle' ? 'active' : null}>
                        lifestyle
                        <QuoteImage src={lifestyleImage} alt="Lifestyle Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'wellness' ? 'active' : null}>
                        wellness
                        <QuoteImage src={wellnessImage} alt="Wellness Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'cbd' ? 'active' : null}>
                        CBD
                        <QuoteImage src={cbdImage} alt="CBD Image"/>
                    </QuoteLink>
                    ,&nbsp;
                    <QuoteLink className={activeLink === 'music' ? 'active' : null}>
                        music
                        <QuoteImage src={musicImage} alt="Music Image"/>
                    </QuoteLink>
                    , and&nbsp;
                    <QuoteLink className={activeLink === 'art' ? 'active' : null}>
                        art
                        <QuoteImage src={artImage} alt="Art Image"/>
                    </QuoteLink>.
                </Quote>
                <ImageCarouselWrapper>
                    <ImageCarouselScrollingDiv onScroll={updateActiveLink}>
                        <ImageCarousel>
                            <Image src={beverageImage} alt="Beverage Image" data-handle="beverage"/>
                            <Image src={supplementsImage} alt="Supplements Image" data-handle="supplements"/>
                            <Image src={fashionImage} alt="Fashion Image" data-handle="fashion"/>
                            <Image src={kidsImage} alt="Kids Image" data-handle="kids"/>
                            <Image src={electronicsImage} alt="Electronics Image" data-handle="electronics"/>
                            <Image src={lifestyleImage} alt="Lifestyle Image" data-handle="lifestyle"/>
                            <Image src={wellnessImage} alt="Wellness Image" data-handle="wellness"/>
                            <Image src={cbdImage} alt="CBD Image" data-handle="cbd"/>
                            <Image src={musicImage} alt="Music Image" data-handle="music"/>
                            <Image src={artImage} alt="Art Image" data-handle="art"/>
                        </ImageCarousel>
                    </ImageCarouselScrollingDiv>
                </ImageCarouselWrapper>
            </WrapperDiv>
        </AboutQuoteDiv>
    );
}

export default AboutQuote