import React, { useState } from "react"
import styled from "styled-components"

const AboutDetailsDiv = styled.div`
    background-color: #4157FE;
    padding: 71px 0 31px;
    margin-bottom: 80px;

    @media only screen and (min-width: 750px) {
        padding: 100px 0 86px;
        margin-bottom: 102px;
    }

    @media only screen and (min-width: 1200px) {
        padding: 153px 0 86px;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 30px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const ToggleWrapperDiv = styled.div`
    border-bottom: 2px solid #FFFFFF;
    margin-bottom: 41px;
    padding-bottom: 13px;
    user-select: none;

    @media only screen and (min-width: 750px) {
        margin-bottom: 47px;
    }

    &:last-child {
        border-bottom: none;

        @media only screen and (min-width: 750px) {
            border-bottom: 2px solid #FFFFFF;
        }
    }
`;

const ToggleDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const ToggleHeadline = styled.h3`
    font-size: 40px;
    line-height: 50px;
    padding: 5px 0 6px;
    letter-spacing: -2px;
    color: #FFFFFF;
    display: inline-block;

    @media only screen and (min-width: 750px) {
        font-size: 60px;
        line-height: 80px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 80px;
        line-height: 96px;
    }
`;

const ToggleArrowDiv = styled.div`
    display: inline-block;
    margin-top: -18px;

    @media only screen and (min-width: 750px) {
        margin-top: -25px;
    }

    .toggle-open & {
        transform: rotate(180deg);
        margin-top: -34px;

        @media only screen and (min-width: 750px) {
            margin-top: -26px;
        }
    }
`;

const ToggleArrowSmallSvg = styled.svg`
    @media only screen and (min-width: 750px) {
        display: none;
    }
`;

const ToggleArrowLargeSvg = styled.svg`
    display: none;

    @media only screen and (min-width: 750px) {
        display: block;
    }
`;

const ToggleContentDiv = styled.div`
    color: #FFFFFF;
    font-size: 16px;
    padding: 15px 0 28px;
    display: none;
    flex-direction: column-reverse;

    .toggle-open & {
        display: flex;
    }

    @media only screen and (min-width: 750px) {
        flex-direction: row;
    }

    @media only screen and (min-width: 1200px) {
        padding: 20px 0 44px;
    }
`;

const ToggleContentP = styled.p`
    width: 100%;
    line-height: 28px;

    @media only screen and (min-width: 750px) {
        width: 50%;
        max-width: 530px;
    }
`;

const ToggleContentBulletsDiv = styled.div`
    width: 100%;
    margin-bottom: 28px;

    @media only screen and (min-width: 750px) {
        width: 50%;
        margin-bottom: 0;
        display: flex;
    }
`;

const ToggleContentUl = styled.ul`
    margin: 0;
    list-style-type: none;

    @media only screen and (min-width: 750px) {
        margin-left: 40px;
        display: inline-block;
        vertical-align: top;
    }

    @media only screen and (min-width: 1024px) {
        margin-left: 40px;
    }

    &:last-child {
        @media only screen and (min-width: 1024px) {
            margin-left: 40px;
        }
    }
`;

const ToggleContentLi = styled.li`
    margin: 0;
    line-height: 28px;
`;

const AboutDetails = () => {
    const [activeToggle, setActiveToggle] = useState('');

    const toggleButton = (handle) => {
        if (handle === activeToggle) {
            setActiveToggle('');
        } else {
            setActiveToggle(handle);
        }
    };

    return (
        <AboutDetailsDiv>
            <WrapperDiv>
                <ToggleWrapperDiv className={activeToggle === 'strategy' ? 'toggle-open' : 'toggle-closed'}>
                    <ToggleDiv onClick={() => toggleButton('strategy')}>
                        <ToggleHeadline>
                            Strategy
                        </ToggleHeadline>
                        <ToggleArrowDiv>
                            <ToggleArrowSmallSvg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9995 0L14.9995 35.7499" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M28 22.75L15 35.75L2.00005 22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowSmallSvg>
                            <ToggleArrowLargeSvg width="52" height="68" viewBox="0 0 52 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 0L26 66" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M50 42L26 66L2 42" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowLargeSvg>
                        </ToggleArrowDiv>
                    </ToggleDiv>
                    <ToggleContentDiv>
                        <ToggleContentP>
                            We believe success is the result of preparation, hard work, and experience. We take a collaborative approach to everything we do and plan customized solutions that meet your goals. By deep diving to understand the needs of your business, we create intentional, thoughtful ecommerce experiences tailored for success at the highest levels.
                        </ToggleContentP>
                        <ToggleContentBulletsDiv>
                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Industry research
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Stakeholder interviews
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Customer Experience Review
                                </ToggleContentLi>
                            </ToggleContentUl>

                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Strategic Roadmapping
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Campaign ideation
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Conversion Rate Optimization
                                </ToggleContentLi>
                            </ToggleContentUl>
                        </ToggleContentBulletsDiv>
                    </ToggleContentDiv>
                </ToggleWrapperDiv>

                <ToggleWrapperDiv className={activeToggle === 'branding' ? 'toggle-open' : 'toggle-closed'}>
                    <ToggleDiv onClick={() => toggleButton('branding')}>
                        <ToggleHeadline>
                            Branding
                        </ToggleHeadline>
                        <ToggleArrowDiv>
                            <ToggleArrowSmallSvg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9995 0L14.9995 35.7499" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M28 22.75L15 35.75L2.00005 22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowSmallSvg>
                            <ToggleArrowLargeSvg width="52" height="68" viewBox="0 0 52 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 0L26 66" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M50 42L26 66L2 42" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowLargeSvg>
                        </ToggleArrowDiv>
                    </ToggleDiv>
                    <ToggleContentDiv>
                        <ToggleContentP>
                            A cohesive visual and verbal experience that communicates the value of your company is essential for success. We aim to create look and feel that is distinctly recognizable, relatable and builds trust with your consumers through education and authenticity. Whether you're just starting out or need a refresh, we'll make your story a bestseller.
                        </ToggleContentP>
                        <ToggleContentBulletsDiv>
                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Creative &amp; Art Direction
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Tone &amp; Key Messaging
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Logo Design
                                </ToggleContentLi>
                            </ToggleContentUl>

                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Brand Guidelines
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Asset Creation
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Package Design
                                </ToggleContentLi>
                            </ToggleContentUl>
                        </ToggleContentBulletsDiv>
                    </ToggleContentDiv>
                </ToggleWrapperDiv>

                <ToggleWrapperDiv className={activeToggle === 'web-design' ? 'toggle-open' : 'toggle-closed'}>
                    <ToggleDiv onClick={() => toggleButton('web-design')}>
                        <ToggleHeadline>
                            Web Design
                        </ToggleHeadline>
                        <ToggleArrowDiv>
                            <ToggleArrowSmallSvg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9995 0L14.9995 35.7499" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M28 22.75L15 35.75L2.00005 22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowSmallSvg>
                            <ToggleArrowLargeSvg width="52" height="68" viewBox="0 0 52 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 0L26 66" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M50 42L26 66L2 42" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowLargeSvg>
                        </ToggleArrowDiv>
                    </ToggleDiv>
                    <ToggleContentDiv>
                        <ToggleContentP>
                            We pride ourselves on impeccable design. Our process presents high fidelity prototypes in an open and collaborative manner, to ultimately drive business objectives and effectively communicate with your target audience.
                        </ToggleContentP>
                        <ToggleContentBulletsDiv>
                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Moodboards
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Figma based prototyping
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    High fidelity Designs
                                </ToggleContentLi>
                            </ToggleContentUl>

                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Assets Creation
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Product renderings
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    AR prototyping
                                </ToggleContentLi>
                            </ToggleContentUl>
                        </ToggleContentBulletsDiv>
                    </ToggleContentDiv>
                </ToggleWrapperDiv>

                <ToggleWrapperDiv className={activeToggle === 'development' ? 'toggle-open' : 'toggle-closed'}>
                    <ToggleDiv onClick={() => toggleButton('development')}>
                        <ToggleHeadline>
                            Development
                        </ToggleHeadline>
                        <ToggleArrowDiv>
                            <ToggleArrowSmallSvg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9995 0L14.9995 35.7499" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M28 22.75L15 35.75L2.00005 22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowSmallSvg>
                            <ToggleArrowLargeSvg width="52" height="68" viewBox="0 0 52 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 0L26 66" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M50 42L26 66L2 42" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowLargeSvg>
                        </ToggleArrowDiv>
                    </ToggleDiv>
                    <ToggleContentDiv>
                        <ToggleContentP>
                            We handle everything you need to get your newly designed site up and running. Our sites, built on Shopify, deliver a user-friendly experience not only for the consumers but tailored towards your business needs. Using modern mobile-first approach and following industry best practices, we ensure that your custom digital experience will reflect your brand and provide a great ecommerce experience for your consumers.
                        </ToggleContentP>
                        <ToggleContentBulletsDiv>
                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Custom theme development
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    App integrations
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Analytics &amp; pixels
                                </ToggleContentLi>
                            </ToggleContentUl>

                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Catalog refactoring
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Accessibility - WCAG 2.0 AA
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Launch &amp; post launch support
                                </ToggleContentLi>
                            </ToggleContentUl>
                        </ToggleContentBulletsDiv>
                    </ToggleContentDiv>
                </ToggleWrapperDiv>

                <ToggleWrapperDiv className={activeToggle === 'app' ? 'toggle-open' : 'toggle-closed'}>
                    <ToggleDiv onClick={() => toggleButton('app')}>
                        <ToggleHeadline>
                            Custom Applications
                        </ToggleHeadline>
                        <ToggleArrowDiv>
                            <ToggleArrowSmallSvg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9995 0L14.9995 35.7499" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M28 22.75L15 35.75L2.00005 22.75" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowSmallSvg>
                            <ToggleArrowLargeSvg width="52" height="68" viewBox="0 0 52 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 0L26 66" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
                                <path d="M50 42L26 66L2 42" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
                            </ToggleArrowLargeSvg>
                        </ToggleArrowDiv>
                    </ToggleDiv>
                    <ToggleContentDiv>
                        <ToggleContentP>
                            Need more out of Shopify? We build custom apps to meet your specific business needs and ensure you are getting the most from the Shopify platform. Our goal is to create custom solutions that will enhance your customers’ e-commerce experiences and automate the parts of your business that you value the most.
                        </ToggleContentP>
                        <ToggleContentBulletsDiv>
                            <ToggleContentUl>
                                <ToggleContentLi>
                                    3PL and ERP integrations
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Custom applications
                                </ToggleContentLi>
                                <ToggleContentLi>
                                    Enhanced Shopify functionality
                                </ToggleContentLi>
                            </ToggleContentUl>

                            <ToggleContentUl>
                                <ToggleContentLi>
                                    Data migrations
                                </ToggleContentLi>
                            </ToggleContentUl>
                        </ToggleContentBulletsDiv>
                    </ToggleContentDiv>
                </ToggleWrapperDiv>
            </WrapperDiv>
        </AboutDetailsDiv>
    );
}

export default AboutDetails